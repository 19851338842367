import React from 'react';
import styled from 'styled-components';
import useUser from 'contexts/UserContext';
import UsernameModal from 'components/UsernameModal';
import SocialLogin from 'components/SocialLogin';
import Navbar from 'components/Navbar';
import RacePanel from 'components/RacePanel';
import InvitationPopup from 'components/InvitationPopup/InvitationPopup';

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Race = () => {
  const [user] = useUser();

  return (
    <Wrapper>
      {user.loaded && user.data && !user.data.name && <UsernameModal />}
      <RacePanel />
      {user.loaded && user.data && <Navbar />}
      {user.loaded && !user.data && <SocialLogin />}
      <InvitationPopup />
    </Wrapper>
  );
};
export default Race;
