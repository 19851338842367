import styled from 'styled-components';

export const ModalBox = styled.div`
  background-color: ${({ theme }) => theme.color.background};
  color: ${({ theme }) => theme.color.primary};
  width: 40rem;
  margin: 0 5rem;
  padding: 7rem;
  border-radius: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 600px) {
    padding: 5rem;
  }
  @media (max-width: 450px) {
    margin: 0 2rem;
  }
`;
