import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import IconButton from 'components/IconButton';
import { ReactComponent as CancelIconBase } from 'assets/cancel.svg';
import Button from 'components/Button';
import useSocket from 'contexts/SocketContext';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Popup from 'components/Popup';

const PopupWrapper = styled.div`
  position: absolute;
  bottom: 2rem;
  width: 100vw;
  display: flex;
  justify-content: center;
`;

const CancelIcon = styled(CancelIconBase)`
  filter: brightness(1.5);
  transform: scale(0.4);
`;

const InvitationPopup: React.FC<RouteComponentProps> = ({ history }) => {
  const socket = useSocket();
  const [invitations, setInvitations] = useState([] as any[]);

  useEffect(() => {
    if (!socket.on) return;
    socket.on('newInvitation', (inv: any) => {
      setInvitations(i => [...i, inv]);
    });
    socket.on('deleteInvitation', (opponentId: string) => {
      setInvitations((invitation: any) =>
        invitation.filter((i: any) => i.id !== opponentId)
      );
    });
  }, [invitations, socket]);

  const accept = () => {
    socket.emit('accept', invitations[0].id);
    history.push('/race');
    setInvitations(i => i.slice(1));
  };

  const reject = () => {
    socket.emit('reject', invitations[0].id);
    setInvitations(i => i.slice(1));
  };

  return (
    <PopupWrapper>
      {invitations && invitations[0] && (
        <Popup>
          <div>
            Invitation from
            {` ${invitations[0].name}`}
          </div>
          <Button color="primary" onClick={accept}>
            Accept
          </Button>
          <IconButton
            background="#AC4141"
            padding="0.2rem"
            title="siema"
            onClick={reject}
          >
            <CancelIcon />
          </IconButton>
        </Popup>
      )}
    </PopupWrapper>
  );
};

export default withRouter(InvitationPopup);
