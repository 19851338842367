import styled, { css, keyframes } from 'styled-components';

export const InputBox = styled.div`
  width: 80rem;
  padding: 2rem;
  border: 4px solid ${({ theme }) => theme.color.primaryDark};
  border-radius: 1.5rem;

  color: ${({ theme }) => theme.color.primary};
  font-size: 2.4rem;
  line-height: 1.4;
  outline: none;
  position: relative;

  @media (max-width: 800px) {
    width: 100vw;
  }
  @media (max-width: 320px) {
    padding: 1rem;
  }
`;

export const Blink = keyframes`
    50% {
    opacity: 50%;
    }
`;

export const Input = styled.input<{ show: boolean }>`
  position: absolute;
  height: 100%;
  width: 100%;
  border: 0;
  border-radius: 1rem;
  color: transparent;
  background-color: ${({ theme }) => theme.color.background};
  transform: translateX(-2rem) translateY(-2rem);
  ${({ show }) =>
    show &&
    css`
      opacity: 0;
      :focus {
        opacity: 0;
      }
    `}
}
  @media (max-width: 320px) {
    transform: translateX(-1rem) translateY(-1rem);
  }
`;

export const InputWrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Placeholder = styled.div`
  position: absolute;
  z-index: 1;
  font-size: 2.4rem;
  text-align: center;
  animation: ${Blink} 1.5s linear infinite;
  pointer-events: none;

  @media (max-width: 425px) {
    font-size: 2rem;
  }
`;
