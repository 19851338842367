import React, { useEffect, useState } from 'react';
import useSocket from 'contexts/SocketContext';
import useUser from 'contexts/UserContext';
import styled from 'styled-components';
import Popup from 'components/Popup';
import Button from 'components/Button';

const RaceInvitation = styled(Popup)`
  justify-content: space-between;
  width: 30rem;
  margin-bottom: 1rem;
`;

const InvitationScrollBox = styled.div`
  margin-top: 1rem;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 50vh;
`;

const Input = styled.input`
  background: transparent;
  border: 3px solid ${({ theme }) => theme.color.primaryDark};
  border-radius: 1rem;
  padding: 1rem;
  padding-left: 4rem;
  width: 30rem;
  color: ${({ theme }) => theme.color.primary};
  outline: none;
  font-size: 1.6rem;
  font-family: 'Fira Code', monospace;

  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxOSAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTggMTZDOS43NzQ5OCAxNS45OTk2IDExLjQ5ODggMTUuNDA1NCAxMi44OTcgMTQuMzEyTDE3LjI5MyAxOC43MDhMMTguNzA3IDE3LjI5NEwxNC4zMTEgMTIuODk4QzE1LjQwNSAxMS40OTk3IDE1Ljk5OTYgOS43NzU0NCAxNiA4QzE2IDMuNTg5IDEyLjQxMSAwIDggMEMzLjU4OSAwIDAgMy41ODkgMCA4QzAgMTIuNDExIDMuNTg5IDE2IDggMTZaTTggMkMxMS4zMDkgMiAxNCA0LjY5MSAxNCA4QzE0IDExLjMwOSAxMS4zMDkgMTQgOCAxNEM0LjY5MSAxNCAyIDExLjMwOSAyIDhDMiA0LjY5MSA0LjY5MSAyIDggMloiIGZpbGw9IiM4NDhEOUIiLz4KPC9zdmc+Cg==');
  background-repeat: no-repeat;
  background-position: left center;
  background-position-x: 1rem;

  :active,
  :focus {
    border-color: ${({ theme }) => theme.color.accent};
  }
`;

const RaceInvitationPanel = () => {
  const [filter, setFilter] = useState('');
  const socket = useSocket();
  const [user] = useUser();
  const [activeUsers, setActiveUsers] = useState([]);

  const getActiveUsers = React.useCallback(() => {
    socket.emit('activeUsers', (users: any) => {
      setActiveUsers(users);
    });
  }, [socket]);

  useEffect(() => {
    getActiveUsers();
    setInterval(getActiveUsers, 1000);
  }, [getActiveUsers]);

  const invite = (id: string) => {
    socket.emit('invite', id);
  };
  return (
    <div>
      <Input
        type="text"
        value={filter}
        onChange={e => setFilter(e.target.value)}
      />
      <InvitationScrollBox>
        {activeUsers
          .filter((u: any) => u.name !== user.data?.name)
          .filter((u: any) => u.name.includes(filter))
          .sort((u1: any, u2: any) => u1.name.localeCompare(u2.name))
          .map((u: any) => (
            <RaceInvitation key={u.id}>
              <div>{u.name}</div>
              <Button color="primary" onClick={() => invite(u.id)}>
                Invite
              </Button>
            </RaceInvitation>
          ))}
      </InvitationScrollBox>
    </div>
  );
};

export default RaceInvitationPanel;
