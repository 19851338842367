import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Label = styled.label`
  font-size: 2rem;
`;

export const Input = styled.input`
  padding: 1rem;
  margin: 2rem 0 1rem;
  width: 100%;
  font-size: 2rem;

  border: 2px solid ${({ theme }) => theme.color.primary};
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.color.primaryFocus};
  background-color: ${({ theme }) => theme.color.background};
  font-family: 'Fira Code', monospace;

  :focus {
    outline: none;
    border-color: ${({ theme }) => theme.color.accent};
  }
`;

export const ValidationLabel = styled.label<{ isError: boolean }>`
  color: ${({ theme, isError }) =>
    isError ? theme.color.typo : theme.color.correct};
  margin: 0.5rem 0;
`;
