import React, { SetStateAction, useState } from 'react';
import styled from 'styled-components';

const Label = styled.label`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.6rem;
  margin: 2rem;
`;

const Select = styled.select`
  padding: 1rem 3rem 1rem 1rem;
  border: 0.2rem solid ${({ theme }) => theme.color.primary};
  border-radius: 1rem;

  font-size: 2rem;
  font-family: 'Fira Code', monospace;

  color: ${({ theme }) => theme.color.primary};
  appearance: none;
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMC41OSAwTDYgNC4zMjY1OUwxLjQxIDBMMCAxLjMzMTk4TDYgN0wxMiAxLjMzMTk4TDEwLjU5IDBaIiBmaWxsPSIjODQ4RDlCIi8+Cjwvc3ZnPg==')
    no-repeat right;
  background-position-x: calc(100% - 1rem);

  :focus {
    outline: none;
    color: ${({ theme }) => theme.color.primaryFocus};
    border-color: ${({ theme }) => theme.color.accent};
  }
`;

export interface Option {
  id: number;
  name: string;
}

type UseDropdown = [React.FC, number, SetStateAction<number>];

const useDropdown = (
  label: string,
  initial: number,
  options: Option[],
  disabled = false
) => {
  const [selected, setSelected] = useState(initial);
  const id = `use-dropdown-${label.replace(' ', '').toLowerCase()}`;

  const Dropdown: React.FC = () => (
    <Label htmlFor={id}>
      {label}
      <Select
        id={id}
        value={selected}
        onChange={e => setSelected(+e.target.value)}
        onBlur={e => setSelected(+e.target.value)}
        disabled={options.length === 0 || disabled}
      >
        {options.map(o => (
          <option key={o.id} value={o.id}>
            {o.name}
          </option>
        ))}
      </Select>
    </Label>
  );

  return [Dropdown, selected, setSelected] as UseDropdown;
};

export default useDropdown;
