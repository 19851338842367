import { Entry, HistoryEntry } from 'types/session';

export const calculateWpm = (entries: Entry[], history: HistoryEntry[]) => {
  const allEntries = history.filter(l => l.key !== 'Backspace').length;
  const uncorrectedErrors = entries.filter(l => l.typo).length;

  const firstEntryTime = history[0].timestamp;
  const lastEntryTime = history[history.length - 1].timestamp;
  const timeInMinutes = (lastEntryTime - firstEntryTime) / 60_000;

  const wpm = (allEntries / 5 - uncorrectedErrors) / timeInMinutes;

  if (wpm < 0) return 0;

  return Math.round(wpm);
};

export const calculateDelay = (history: HistoryEntry[]) => {
  if (!history.length) return null;
  const lastTimestamp = history.slice(-1)[0]?.timestamp;
  const timeDiff = Date.now() - lastTimestamp;
  if (timeDiff > 2000) return null;
  return timeDiff;
};
