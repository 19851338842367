import React from 'react';
import { Key, KeyboardBase, KeyRow } from './Keyboard.style';

export enum KeyType {
  MODIFIER = 1.25,
  TAB = 1.5,
  CAPS = 1.75,
  BACKSPACE = 2,
  ENTER = 2.25,
  RIGHT_SHIFT = 2.75,
  SPACE = 6.25,
}

export interface KeyboardProps {
  keysDown: Set<string>;
}

const Keyboard: React.FC<KeyboardProps> = ({ keysDown }) => {
  const isActive = (...keys: string[]) => {
    let ret = false;
    keys.forEach(k => {
      if (keysDown.has(k.toLowerCase())) {
        ret = true;
      }
    });
    return ret;
  };
  return (
    <KeyboardBase>
      <KeyRow>
        <Key active={isActive('`', '~')}>`</Key>
        <Key active={isActive('1', '!')}>1</Key>
        <Key active={isActive('2', '@')}>2</Key>
        <Key active={isActive('3', '#')}>3</Key>
        <Key active={isActive('4', '$')}>4</Key>
        <Key active={isActive('5', '%')}>5</Key>
        <Key active={isActive('6', '^')}>6</Key>
        <Key active={isActive('7', '&')}>7</Key>
        <Key active={isActive('8', '*')}>8</Key>
        <Key active={isActive('9', '(')}>9</Key>
        <Key active={isActive('0', ')')}>0</Key>
        <Key active={isActive('-', '_')}>-</Key>
        <Key active={isActive('=', '+')}>=</Key>
        <Key
          width={KeyType.BACKSPACE}
          active={isActive('Backspace', 'backspace')}
        />
      </KeyRow>
      <KeyRow>
        <Key width={KeyType.TAB} />
        <Key active={isActive('Q')}>Q</Key>
        <Key active={isActive('W')}>W</Key>
        <Key active={isActive('E')}>E</Key>
        <Key active={isActive('R')}>R</Key>
        <Key active={isActive('T')}>T</Key>
        <Key active={isActive('Y')}>Y</Key>
        <Key active={isActive('U')}>U</Key>
        <Key active={isActive('I')}>I</Key>
        <Key active={isActive('O')}>O</Key>
        <Key active={isActive('P')}>P</Key>
        <Key active={isActive('[', '{')}>[</Key>
        <Key active={isActive(']', '}')}>]</Key>
        <Key width={KeyType.TAB} active={isActive('\\', '|')}>
          \
        </Key>
      </KeyRow>
      <KeyRow>
        <Key width={KeyType.CAPS} />
        <Key active={isActive('A')}>A</Key>
        <Key active={isActive('S')}>S</Key>
        <Key active={isActive('D')}>D</Key>
        <Key active={isActive('F')}>F</Key>
        <Key active={isActive('G')}>G</Key>
        <Key active={isActive('H')}>H</Key>
        <Key active={isActive('J')}>J</Key>
        <Key active={isActive('K')}>K</Key>
        <Key active={isActive('L')}>L</Key>
        <Key active={isActive(';', ':')}>;</Key>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <Key active={isActive("'", '"')}>'</Key>
        <Key width={KeyType.ENTER} active={isActive('enter')} />
      </KeyRow>
      <KeyRow>
        <Key width={KeyType.ENTER} active={isActive('shift')} />
        <Key active={isActive('Z')}>Z</Key>
        <Key active={isActive('X')}>X</Key>
        <Key active={isActive('C')}>C</Key>
        <Key active={isActive('V')}>V</Key>
        <Key active={isActive('B')}>B</Key>
        <Key active={isActive('N')}>N</Key>
        <Key active={isActive('M')}>M</Key>
        <Key active={isActive(',', '<')}>,</Key>
        <Key active={isActive('.', '>')}>.</Key>
        <Key active={isActive('/', '?')}>/</Key>
        <Key width={KeyType.RIGHT_SHIFT} active={isActive('shift')} />
      </KeyRow>
      <KeyRow>
        <Key width={KeyType.MODIFIER} active={isActive('control')} />
        <Key width={KeyType.MODIFIER} active={isActive('alt')} />
        <Key width={KeyType.MODIFIER} active={isActive('meta')} />
        <Key width={KeyType.SPACE} active={isActive(' ')} />
        <Key width={KeyType.MODIFIER} active={isActive('alt')} />
        <Key width={KeyType.MODIFIER} />
        <Key width={KeyType.MODIFIER} />
        <Key width={KeyType.MODIFIER} active={isActive('control')} />
      </KeyRow>
    </KeyboardBase>
  );
};

export default Keyboard;
