import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Option } from 'hooks/useDropdown';
import useUser from 'contexts/UserContext';

interface WordSet {
  id: number;
  name: string;
}

interface Difficulty {
  id: number;
  name?: string;
  topFraction?: number;
  showProgress?: boolean;
  showCursor?: boolean;
}

interface Settings {
  showKeyboard: boolean;
  showWpm: boolean;
  wordSet: { id: number; name?: string };
  difficulty: Difficulty;
}

const init = {
  showKeyboard: true,
  showWpm: true,
  wordSet: {} as WordSet,
  difficulty: {} as Difficulty,
} as Settings;

type SettingsContextProps = [
  Settings,
  (settings: Settings) => void,
  WordSet[],
  Difficulty[]
];

const SettingsContext = createContext<SettingsContextProps>([
  init,
  {} as (s: Settings) => void,
  [{ id: 1, name: 'English' }],
  [{ id: 1, name: 'Easy' }],
]);

export const SettingsProvider: React.FC = ({ children }) => {
  const [settings, setSettings] = useState(init);
  const [wordSets, setWordSets] = useState([] as Option[]);
  const [difficulties, setDifficulties] = useState([] as Option[]);
  const [user] = useUser();

  const fetchSettings = () => {
    if (user.data) {
      axios
        .get('/api/settings', {
          withCredentials: true,
        })
        .then(({ data }) => {
          setSettings(data);
        });
    }
    axios.get('/api/settings/wordsets').then(({ data }) => {
      setWordSets(data);
    });
    axios.get('/api/settings/difficulties').then(({ data }) => {
      setDifficulties(data);
    });
  };
  useEffect(fetchSettings, [user.data]);

  const updateSettings = (updated: Settings) => {
    if (user.data) {
      axios.put('/api/settings', updated, { withCredentials: true });
    }
    setSettings(updated);
  };

  return (
    <SettingsContext.Provider
      value={[settings, updateSettings, wordSets, difficulties]}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default function useSettings() {
  return useContext(SettingsContext);
}
