import React from 'react';
import styled from 'styled-components';

const IconButtonBase = styled.button<{
  background: string;
  padding: string;
}>`
  display: flex;
  align-items: center;
  border: none;
  border-radius: 0.5rem;
  background: ${({ background }) => background};
  padding: ${({ padding }) => padding};

  :hover {
    filter: brightness(1.2);
  }

  :focus {
    outline: 1px solid royalblue;
    filter: brightness(1.2);
  }

  :active {
    filter: brightness(0.8);
  }
`;

interface IconButtonProps {
  title: string;
  onClick: () => void;
  background?: string;
  padding?: string;
}

const IconButton: React.FC<IconButtonProps> = ({
  title,
  onClick,
  children,
  background = 'transparent',
  padding = '0.5rem',
}) => (
  <IconButtonBase
    background={background}
    padding={padding}
    onClick={onClick}
    title={title}
  >
    {children}
  </IconButtonBase>
);

export default IconButton;
