import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const SocialWrapper = styled.footer`
  display: grid;
  grid-template-rows: 1fr 1fr;
  width: 12rem;
  margin: 3rem;
  font-size: 2rem;
  color: ${({ theme }) => theme.color.primary};
  position: fixed;
  bottom: 0;

  & > * {
    margin: 1rem 0;
  }
  @media (max-width: 425px) {
    margin: 0 0 3rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  @media (max-width: 375px) {
    font-size: 1.6rem;
  }
`;

export const StyledLink = styled(NavLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.color.primary};
  :hover {
    color: ${({ theme }) => theme.color.primaryFocus};
  }
  :active {
    color: ${({ theme }) => theme.color.primaryDark};
  }

  &.active {
    color: ${({ theme }) => theme.color.accent};
  }
`;
export const StyledAnchor = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.color.primary};
  :hover {
    color: ${({ theme }) => theme.color.primaryFocus};
  }
  :active {
    color: ${({ theme }) => theme.color.primaryDark};
  }
`;
