import styled, { css } from 'styled-components';
import { KeyType } from './Keyboard';

export const Key = styled.span<{ width?: KeyType; active?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 5rem;
  width: ${({ width }) => (width || 1) * 5}rem;
  padding: 0.5rem 1rem;
  border: 2px solid ${({ theme }) => theme.color.primaryDark};
  border-radius: 1rem;

  transition: border-color 0.1s ease-out;

  :hover {
    border-color: ${({ theme }) => theme.color.primary};
  }
  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.color.primaryDark};
      color: ${({ theme }) => theme.color.background};
    `}
`;

export const KeyRow = styled.div`
  display: flex;
  margin-bottom: 0.4rem;
  justify-content: space-between;
`;

export const KeyboardBase = styled.div`
  display: grid;
  width: 80rem;
  margin-top: 8rem;

  @media (max-width: 800px) {
    display: none;
  }
  @media (max-height: 700px) {
    display: none;
  }
`;
