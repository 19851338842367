import React, { Dispatch, SetStateAction, useState } from 'react';
import Modal from 'components/Modal';

type UseModal = [React.FC, boolean, Dispatch<SetStateAction<boolean>>];

const useModal = (initShow = false) => {
  const [visible, setVisible] = useState(initShow);

  const RenderModal: React.FC = ({ children }) => {
    if (!visible) return null;
    return <Modal>{children}</Modal>;
  };

  return [RenderModal, visible, setVisible] as UseModal;
};

export default useModal;
