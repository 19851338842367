import styled from 'styled-components';

export const SocialWrapper = styled.footer`
  display: grid;
  grid-template-rows: 1fr 1fr;
  max-width: 12rem;
  max-height: 7.5rem;
  margin: 3rem;
  position: absolute;
  bottom: 0;
`;
export const IconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Label = styled.label`
  text-align: center;
  color: ${({ theme }) => theme.color.primary};
`;

export const IconAnchor = styled.a`
  padding: 0;
  border: 0;
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
  :hover {
    filter: brightness(1.3);
  }
  :active {
    filter: brightness(0.8);
  }
`;
