import styled from 'styled-components';
import { ReactComponent as SettingsIconBase } from 'assets/settings.svg';
import { ReactComponent as CancelIconBase } from 'assets/cancel.svg';

export const PracticeSection = styled.main`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: 20vh;

  @media (max-width: 425px), (max-height: 760px) {
    margin-top: 1rem;
  }
`;

export const WpmHeader = styled.h2`
  display: flex;
  align-items: center;
  @media (max-width: 320px) {
    font-size: 1.6rem;
  }
`;

export const SettingsIcon = styled(SettingsIconBase)`
  transform: scale(0.7);
`;

export const CancelIcon = styled(CancelIconBase)`
  transform: scale(0.7);
`;
