import React, { memo } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Modal = ({ children }: any) => {
  const element = document.getElementById('modal-root') as Element;
  if (!element) return null;

  return createPortal(<Background>{children}</Background>, element);
};

export default memo(Modal);
