import React from 'react';
import useModal from 'hooks/useModal';
import UsernameModalForm from 'components/UsernameModalForm';
import { ModalBox } from './UsernameModal.style';

const UsernameModal: React.FC = () => {
  const [RenderModal, , setShowModal] = useModal(true);

  return (
    <RenderModal>
      <ModalBox>
        <UsernameModalForm
          onSuccess={() => {
            setShowModal(false);
          }}
        />
      </ModalBox>
    </RenderModal>
  );
};

export default UsernameModal;
