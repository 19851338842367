import styled, { css, DefaultTheme, keyframes } from 'styled-components';

export const Blink = ({ theme }: { theme: DefaultTheme }) => keyframes`
  from,to {
    color: ${theme.color.background};
    background-color: ${theme.color.primaryFocus};
  }
  50% {
    color: ${theme.color.primaryFocus};
    background-color: transparent;
  }
`;

export const WhiteSpace = styled.span`
  font-size-adjust: 0.9;
`;

export interface CursorProps {
  active: boolean;
  focus: boolean;
  typo: boolean;
  beforeCurrent: boolean;
  space: boolean;
  showProgress: boolean;
  theme: DefaultTheme;
}
const getLetterColor = ({
  theme,
  focus,
  typo,
  beforeCurrent,
  space,
  showProgress,
}: CursorProps) => {
  if (!showProgress) return theme.color.primary;

  if (beforeCurrent) {
    if (typo) {
      return theme.color.typo;
    }
    return theme.color.correct;
  }
  if (focus) {
    return theme.color.primaryFocus;
  }
  if (space && !typo) {
    return theme.color.primaryDark;
  }
  return theme.color.primary;
};

export const Cursor = styled.span<CursorProps>`
  color: ${getLetterColor};

  ${({ active }) =>
    active &&
    css`
      animation: 0.75s ${Blink} step-end infinite;
    `}

  @media(max-width: 375px) {
    font-size: 1.6rem;
  }
`;
