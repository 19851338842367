import React from 'react';
import PracticePanel from 'components/PracticePanel';
import styled from 'styled-components';
import SocialLogin from 'components/SocialLogin';
import useUser from 'contexts/UserContext';
import Navbar from 'components/Navbar';
import UsernameModal from 'components/UsernameModal';
import InvitationPopup from 'components/InvitationPopup';

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Practice = () => {
  const [user] = useUser();

  return (
    <Wrapper>
      {user.loaded && user.data && !user.data.name && <UsernameModal />}
      <PracticePanel />
      {user.loaded && user.data && <Navbar />}
      {user.loaded && !user.data && <SocialLogin />}
      <InvitationPopup />
    </Wrapper>
  );
};

export default Practice;
