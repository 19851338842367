import React from 'react';
import styled, { css, DefaultTheme } from 'styled-components';

type ProgressBarColor = 'primary' | 'secondary' | 'error';

const getProgressBarColor = (color: ProgressBarColor, theme: DefaultTheme) => {
  if (color === 'primary') {
    return theme.color.accent;
  }
  if (color === 'error') {
    return theme.color.typo;
  }
  return theme.color.primaryDark;
};

const ProgressBar = styled.progress<{ color: ProgressBarColor }>`
  position: relative;
  appearance: none;
  margin: 1rem;
  background: none;
  height: 2rem;
  width: 40rem;

  border: 3px solid ${({ theme }) => theme.color.primaryDark};
  border-radius: 1rem;

  ::-webkit-progress-bar {
    background: none;
    border-radius: 1rem;
  }

  ::-webkit-progress-value {
    border-radius: 1rem 0 0 1rem;
    background: orangered;
  }
  ::-moz-progress-bar {
    border-radius: 1rem 0 0 1rem;
    background: orangered;
  }

  ${({ color, theme }) => {
    const barColor = getProgressBarColor(color, theme);
    return css`
      border-color: ${barColor};
      ::-webkit-progress-value {
        background: ${barColor};
      }
      ::-moz-progress-bar {
        background: ${barColor};
      }
    `;
  }}
`;

const ProgressBarWrapper = styled.div`
  position: relative;
`;

const ProgressBarLabel = styled.div`
  position: absolute;
  top: calc(50% + -0.2rem);
  left: -18rem;
  transform: translateY(-50%);

  width: 18rem;
  text-align: right;
`;

interface ProgressBarWithLabelProps {
  title: string;
  value: number;
  max: number;
  color: ProgressBarColor;
}

export const ProgressBarWithLabel: React.FC<ProgressBarWithLabelProps> = ({
  title,
  value,
  max,
  color,
}) => {
  return (
    <ProgressBarWrapper>
      <ProgressBarLabel>{title}</ProgressBarLabel>
      <ProgressBar id="name" value={value} max={max} color={color} />
    </ProgressBarWrapper>
  );
};

export default ProgressBar;
