import styled from 'styled-components';

export const RaceSection = styled.main`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: 20vh;
`;

export const ProgressSection = styled.section`
  margin-bottom: 1rem;
`;

export const MainLabel = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 6rem;
`;
