import React from 'react';
import { ReactComponent as GoogleIcon } from 'assets/google.svg';
import { ReactComponent as FacebookIcon } from 'assets/facebook.svg';
import { ReactComponent as GithubIcon } from 'assets/github.svg';
import axios from 'axios';
import {
  IconAnchor,
  IconWrapper,
  Label,
  SocialWrapper,
} from 'components/SocialLogin/SocialLogin.style';

const SocialLogin: React.FC = () => {
  return (
    <SocialWrapper>
      <Label>Log in with:</Label>
      <IconWrapper>
        <IconAnchor
          title="Google"
          href={`${axios.defaults.baseURL}/auth/google`}
        >
          <GoogleIcon />
        </IconAnchor>
        <IconAnchor
          title="Facebook"
          href={`${axios.defaults.baseURL}/auth/facebook`}
        >
          <FacebookIcon />
        </IconAnchor>
        <IconAnchor
          title="GitHub"
          href={`${axios.defaults.baseURL}/auth/github`}
        >
          <GithubIcon />
        </IconAnchor>
      </IconWrapper>
    </SocialWrapper>
  );
};

export default SocialLogin;
