import 'styled-components';

const theme = {
  color: {
    primaryFocus: '#C4C4C4',
    primary: '#848D9B',
    primaryDark: '#4B5362',

    background: '#2F333D',
    correct: '#1D870B',
    typo: '#c00f0f',
    accent: 'royalblue',
  },
};

declare module 'styled-components' {
  type Theme = typeof theme;
  // eslint-disable-next-line
  export interface DefaultTheme extends Theme {}
}

export default theme;
