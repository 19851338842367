import React from 'react';
import useUser from 'contexts/UserContext';
import {
  StyledLink,
  SocialWrapper,
  StyledAnchor,
} from 'components/Navbar/Navbar.style';

const Navbar: React.FC = () => {
  const [user] = useUser();
  return (
    <SocialWrapper>
      {user.loaded ? (
        <div>{user.data?.name ? user.data.name : 'Profile'}</div>
      ) : null}
      <StyledLink exact to="/">
        Practice
      </StyledLink>
      <StyledLink to="/race">Race</StyledLink>
      <StyledAnchor
        onClick={() => {
          localStorage.removeItem('jwt');
          window.location.href = '/';
        }}
      >
        Logout
      </StyledAnchor>
    </SocialWrapper>
  );
};

export default Navbar;
