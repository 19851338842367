export const nextSpace = (index: number, text: string[]) => {
  const relativeIndex = text.slice(index, text.length).indexOf(' ');

  if (relativeIndex === -1) return relativeIndex;
  return index + relativeIndex;
};

export const previousSpace = (index: number, text: string[]) => {
  const lastIndexOfSpace = text.slice(0, index).lastIndexOf(' ');

  if (index === -1 || lastIndexOfSpace === -1) return -1;
  return lastIndexOfSpace;
};
