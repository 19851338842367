import React, { useEffect, useReducer } from 'react';
import Letter from 'components/Letter';
import { nextSpace, previousSpace } from 'utils/string';
import { Session } from 'types/session';
import sessionReducer from 'reducers/sessionReducer';
import useSettings from 'contexts/SettingsContext';
import { Input, InputBox, InputWrapper, Placeholder } from 'components/Input';

export interface PracticeInputPanelProps {
  text: string[];
  next: (session: Session) => void;
  fetch: () => void;
  keyDown: (key: string) => void;
  keyUp: (key: string) => void;
  blur: () => void;
}

const PracticeInputPanel: React.FC<PracticeInputPanelProps> = ({
  text,
  next,
  fetch,
  keyDown,
  keyUp,
  blur,
}) => {
  const [session, dispatch] = useReducer(sessionReducer, {
    current: -1,
    entries: [],
    history: [],
  });
  const [settings] = useSettings();

  useEffect(() => {
    if (session.current === text.length) {
      if (next) next(session);
      dispatch({ type: 'RESET' });
      dispatch({ type: 'FOCUS' });
    }
  }, [session]); // eslint-disable-line

  const inCurrentWord = (i: number) => {
    return (
      i > previousSpace(session.current, text) &&
      i < nextSpace(session.current, text)
    );
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (keyDown) keyDown(e.key.toLowerCase());
    if (session.current === Math.floor(text.length / 2) && fetch) fetch();

    dispatch({
      type: 'ADD_KEY',
      payload: {
        key: e.key,
        typo: e.key !== text[session.current],
      },
    });
  };

  const onKeyUp = (e: React.KeyboardEvent) => {
    if (keyUp) keyUp(e.key.toLowerCase());
  };

  const onFocus = () => {
    dispatch({ type: 'FOCUS' });
  };

  const onBlur = () => {
    dispatch({ type: 'RESET' });
    if (blur) blur();
  };

  return (
    <InputWrapper>
      <Placeholder>{session.current === -1 && 'Click to start...'}</Placeholder>
      <InputBox>
        <Input
          tabIndex={0}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onFocus={onFocus}
          onBlur={onBlur}
          show={session.current !== -1}
        />
        {text.map((l, i) => (
          <Letter
            key={i}
            letter={l}
            cursorActive={
              (settings.difficulty?.showCursor ?? true) && i === session.current
            }
            focus={inCurrentWord(i)}
            typo={session.entries[i]?.typo}
            beforeCurrent={i < session.current}
            showProgress={settings.difficulty?.showProgress ?? true}
          />
        ))}
      </InputBox>
    </InputWrapper>
  );
};

export default React.memo(PracticeInputPanel);
