import styled from 'styled-components';

interface ButtonProps {
  color: 'primary' | 'secondary';
}

const Button = styled.button<ButtonProps>`
  padding: 0.5rem;
  border: none;
  border-radius: 0.5rem;
  color: white;
  background-color: ${({ theme, color }) => {
    if (color === 'primary') return theme.color.accent;
    return theme.color.primary;
  }};

  font-size: 1.6rem;
  font-family: 'Fira Code', monospace;

  :hover {
    filter: brightness(110%);
  }
  :active {
    filter: brightness(90%);
  }
`;

export const FormButton = styled.button<ButtonProps>`
  width: 100%;
  padding: 1rem;
  border: none;
  border-radius: 0.5rem;
  color: white;
  background-color: ${({ theme, color }) => {
    if (color === 'primary') return theme.color.accent;
    return theme.color.primary;
  }};
  margin-top: 1rem;

  font-size: 2rem;
  font-family: 'Fira Code', monospace;

  :hover {
    filter: brightness(110%);
  }
  :active {
    filter: brightness(90%);
  }
`;

export default Button;
