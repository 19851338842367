import React, { useState } from 'react';
import Kb from 'components/Keyboard';

type UseKeyboard = [
  React.FC,
  (key: string) => void,
  (key: string) => void,
  () => void
];

const useKeyboard = (show = true) => {
  const [keysDown, setKeysDown] = useState(new Set<string>());

  const addKey = (key: string) => {
    const newKeys = new Set(keysDown);
    newKeys.add(key);
    setKeysDown(newKeys);
  };
  const removeKey = (key: string) => {
    const newKeys = new Set(keysDown);
    newKeys.delete(key);
    setKeysDown(newKeys);
  };
  const reset = () => {
    setKeysDown(new Set());
  };

  const Keyboard: React.FC = () => (show ? <Kb keysDown={keysDown} /> : null);

  return [Keyboard, addKey, removeKey, reset] as UseKeyboard;
};

export default useKeyboard;
