import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import config from 'config/baseUrl';

interface User {
  name: string;
}

export interface UserFetch {
  loaded: boolean;
  data: User | null;
}

const initUser: UserFetch = { loaded: false, data: null };

type UserContextProps = [UserFetch, (updated: User) => Promise<void>];

const UserContext = createContext<UserContextProps>([
  initUser,
  () => Promise.resolve(),
]);

export const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState(initUser);

  const fetchUser = () => {
    config();
    axios
      .get('/auth/user')
      .then(({ data }) => {
        setUser({ loaded: true, data });
      })
      .catch(() => {
        setUser({ loaded: true, data: null });
      });
  };

  const updateUser = (updated: Partial<User>) => {
    return axios.put('/auth/user', updated).then(fetchUser);
  };
  useEffect(fetchUser, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (!params.has('token')) return;

    localStorage.setItem('jwt', params.get('token') || '');
    window.location.href = '/';
    fetchUser();
  }, []);

  return (
    <UserContext.Provider value={[user, updateUser]}>
      {children}
    </UserContext.Provider>
  );
};

export default function useUser() {
  return useContext(UserContext);
}
