import axios from 'axios';

export const baseUrl =
  process.env.REACT_APP_SERVER_URL || 'http://localhost:8080';

const config = () => {
  axios.defaults.baseURL = baseUrl;
  axios.defaults.withCredentials = true;
  const token = localStorage.getItem('jwt');
  if (token) {
    axios.defaults.headers = {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    };
  }
};

export default config;
