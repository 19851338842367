import React, { useEffect, useRef, useState } from 'react';
import {
  MainLabel,
  ProgressSection,
  RaceSection,
} from 'components/RacePanel/RacePanel.style';
import useKeyboard from 'hooks/useKeyboard';
import { Entry, Session } from 'types/session';
import useSocket from 'contexts/SocketContext';
import useUser from 'contexts/UserContext';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import RaceInputPanel from 'components/RaceInputPanel/RaceInputPanel';
import { ProgressBarWithLabel } from 'components/ProgressBar/ProgressBar';
import RaceInvitationPanel from 'components/RaceInvitationPanel';

const noTyposBefore = (entries: Entry[]) => {
  return !entries || entries.findIndex(e => e.typo) === -1;
};

const RacePanel: React.FC<RouteComponentProps> = ({ history }) => {
  const [Keyboard, addKey, removeKey] = useKeyboard(true);
  const [text, setText] = useState('');
  const [current, setCurrent] = useState(0);
  const [canFinish, setCanFinish] = useState(true);
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const socket = useSocket();
  const [user] = useUser();
  const [opponentProgress, setOpponentProgress] = useState(0);

  const [mainLabel, setMainLabel] = useState('waiting');
  const [gameState, setGameState] = useState(
    'waiting' as 'waiting' | 'countdown' | 'running' | 'finish'
  );

  useEffect(() => {
    if (socket.emit && user.loaded) {
      socket.on('3', () => {
        setMainLabel('3');
        setGameState('countdown');
      });
      socket.on('2', () => setMainLabel('2'));
      socket.on('1', () => setMainLabel('1'));
      socket.on('start', (newText: string) => {
        setGameState('running');
        setText(newText);
        if (inputRef.current) inputRef.current.focus();
      });
      socket.on('updateProgress', (progress: number) =>
        setOpponentProgress(progress)
      );
      socket.on('win', () => {
        setMainLabel('You won!!!');
        setGameState('finish');
        setTimeout(() => {
          setGameState('waiting');
        }, 3000);
      });
      socket.on('lose', () => {
        setMainLabel('You lost :(');
        setGameState('finish');
        setTimeout(() => {
          setGameState('waiting');
        }, 3000);
      });
      socket.on('opponentLeft', () => {
        setGameState('waiting');
      });
    }
  }, [history, inputRef, socket, user.data, user.loaded]);

  useEffect(() => {
    socket.emit('updateProgress', current);
  }, [current, socket]);

  const updateProgress = (session: Session) => {
    if (noTyposBefore(session.entries)) {
      setCurrent(session.current);
      setCanFinish(true);
    } else {
      setCanFinish(false);
    }
  };

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, [inputRef]);

  if (gameState === 'waiting') {
    return (
      <RaceSection>
        <RaceInvitationPanel />
      </RaceSection>
    );
  }
  if (gameState === 'countdown' || gameState === 'finish') {
    return <MainLabel>{mainLabel}</MainLabel>;
  }

  return (
    <RaceSection>
      <ProgressSection>
        <ProgressBarWithLabel
          title="You:"
          value={current}
          max={text.length}
          color={canFinish ? 'primary' : 'error'}
        />
        <ProgressBarWithLabel
          title="Opponent:"
          value={opponentProgress}
          max={text.length}
          color="secondary"
        />
      </ProgressSection>
      {gameState && text.length !== current && (
        <RaceInputPanel
          ref={inputRef}
          text={text.split('')}
          keyDown={addKey}
          keyUp={removeKey}
          updateProgress={updateProgress}
          canFinish={canFinish}
          blur={() => {
            inputRef.current.focus();
          }}
        />
      )}
      <Keyboard />
    </RaceSection>
  );
};

export default withRouter(RacePanel);
