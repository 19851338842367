import { Entry, Session } from 'types/session';
import { calculateDelay } from 'utils/typing';

export interface SessionAction {
  type: 'ADD_KEY' | 'RESET' | 'FOCUS';
  payload?: Session | Entry;
}
const sessionReducer = (state: Session, action: SessionAction) => {
  if (action.type === 'ADD_KEY') {
    const entry = action.payload as Entry;
    const typedSingleChar = entry.key.length === 1;
    const typedBackspace = entry.key === 'Backspace';
    const notFirstChar = state.current > 0;

    const newHistory = [
      ...state.history,
      {
        ...entry,
        timestamp: Date.now(),
        delay: calculateDelay(state.history),
      },
    ];

    if (typedBackspace && notFirstChar) {
      return {
        current: state.current - 1,
        entries: state.entries.slice(0, -1),
        history: state.history,
      };
    }

    if (typedSingleChar) {
      return {
        ...state,
        current: state.current + 1,
        entries: [...state.entries, entry],
        history: newHistory,
      };
    }
  }
  if (action.type === 'RESET') {
    return { ...state, current: -1, entries: [], history: [] };
  }
  if (action.type === 'FOCUS') {
    return { ...state, current: 0 };
  }
  return state;
};

export default sessionReducer;
