import React, { createContext, useContext, useEffect, useState } from 'react';
import openSocket from 'socket.io-client';
import { baseUrl } from 'config/baseUrl';
import useUser from 'contexts/UserContext';

const SocketContext = createContext({} as SocketIOClient.Socket);

export const SocketProvider: React.FC = ({ children }) => {
  const [socket, setSocket] = useState({} as SocketIOClient.Socket);
  const [user] = useUser();

  useEffect(() => {
    setSocket(openSocket(`${baseUrl}`));
  }, []);

  useEffect(() => {
    if (user.loaded) socket.emit('username', user.data?.name);
  }, [socket, user]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export default function useSocket() {
  return useContext(SocketContext);
}
