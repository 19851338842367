import React, { FormEvent, useState } from 'react';
import useUser from 'contexts/UserContext';
import { FormButton } from 'components/Button';
import { Form, Input, Label, ValidationLabel } from './UsernameModalForm.style';

interface UsernameModalFormProps {
  onSuccess: () => void;
}

const initValidation = {
  specialChars: true,
  length: true,
  alreadyTaken: false,
};

export function validateUsername(value: string) {
  return {
    specialChars: !/^[a-zA-Z0-9]*$/.test(value),
    length: value.length < 5 || value.length > 15,
  };
}

const UsernameModalForm: React.FC<UsernameModalFormProps> = ({ onSuccess }) => {
  const [validation, setValidation] = useState(initValidation);
  const [input, setInput] = useState('');
  const [, updateUser] = useUser();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValidation({ ...validateUsername(e.target.value), alreadyTaken: false });
    setInput(e.target.value);
  };

  const submit = (e: FormEvent) => {
    e.preventDefault();
    const { specialChars, length } = validation;
    if (specialChars || length) return;

    updateUser({ name: input })
      .then(onSuccess)
      .catch(() => {
        setValidation(v => ({ ...v, alreadyTaken: true }));
      });
  };

  return (
    <Form onSubmit={submit}>
      <Label>Enter your username</Label>
      <Input value={input} onChange={onChange} autoFocus />
      {validation.alreadyTaken && (
        <ValidationLabel isError={validation.alreadyTaken}>
          Username is already taken
        </ValidationLabel>
      )}
      <ValidationLabel isError={validation.specialChars}>
        Only letters and numbers
      </ValidationLabel>
      <ValidationLabel isError={validation.length}>
        At least 5 characters
      </ValidationLabel>
      <FormButton color="primary" type="submit">
        Submit
      </FormButton>
    </Form>
  );
};

export default UsernameModalForm;
