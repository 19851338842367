import React from 'react';
import { Cursor, WhiteSpace } from './Letter.style';

export interface LetterProps {
  letter: string;
  cursorActive: boolean;
  focus: boolean;
  beforeCurrent: boolean;
  typo: boolean;
  showProgress: boolean;
}

const Letter: React.FC<LetterProps> = ({
  letter,
  cursorActive,
  focus,
  typo,
  beforeCurrent,
  showProgress,
}) => (
  <Cursor
    active={cursorActive}
    focus={focus}
    typo={typo}
    beforeCurrent={beforeCurrent}
    space={letter === ' '}
    showProgress={showProgress}
  >
    {letter !== ' ' ? (
      letter
    ) : (
      <WhiteSpace>
        ␣
        <wbr />
      </WhiteSpace>
    )}
  </Cursor>
);

export default React.memo(Letter);
