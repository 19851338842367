import React from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { BrowserRouter, Route } from 'react-router-dom';
import useUser, { UserProvider } from 'contexts/UserContext';
import Practice from 'pages/Practice';
import { SettingsProvider } from 'contexts/SettingsContext';
import theme from 'theme';
import { SocketProvider } from 'contexts/SocketContext';
import Race from 'pages/Race';

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after{
    box-sizing: border-box;
  }
  html {
    font-size: 62.5%;
  } 
  
  body{
      margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${p => p.theme.color.primary};
    background: ${p => p.theme.color.background};
    
    font-family: 'Fira Code', monospace;
    font-size: 1.6rem;
    font-variant-ligatures: none;
  }
`;

const Routes: React.FC = () => {
  const [user] = useUser();
  return (
    <BrowserRouter>
      <SocketProvider>
        <Route exact path={['/', '/practice']}>
          <SettingsProvider>
            <Practice />
          </SettingsProvider>
        </Route>
        <Route exact path="/race">
          {user.loaded && user.data && <Race />}
        </Route>
      </SocketProvider>
    </BrowserRouter>
  );
};

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <UserProvider>
        <Routes />
      </UserProvider>
    </ThemeProvider>
  );
};

export default App;
