import React, { forwardRef, useEffect, useReducer } from 'react';
import Letter from 'components/Letter';
import { nextSpace, previousSpace } from 'utils/string';
import { Session } from 'types/session';
import sessionReducer from 'reducers/sessionReducer';
import { Input, InputBox, InputWrapper, Placeholder } from 'components/Input';

export interface RaceInputPanelProps {
  text: string[];
  keyDown: (key: string) => void;
  keyUp: (key: string) => void;
  updateProgress: (current: Session) => void;
  canFinish: boolean;
  blur: () => void;
}

const RaceInputPanel = forwardRef<HTMLInputElement, RaceInputPanelProps>(
  (
    { text, keyDown, keyUp, updateProgress, canFinish, blur },
    inputRef: React.Ref<HTMLInputElement>
  ) => {
    const [session, dispatch] = useReducer(sessionReducer, {
      current: 0,
      entries: [],
      history: [],
    });

    useEffect(() => {
      if (updateProgress) updateProgress(session);
    }, [session]); //eslint-disable-line

    const inCurrentWord = (i: number) => {
      return (
        i > previousSpace(session.current, text) &&
        i < nextSpace(session.current, text)
      );
    };

    const isNotAllowedToType = (e: React.KeyboardEvent) => {
      return (
        session.current === text.length - 1 &&
        e.key !== 'Backspace' &&
        (!canFinish || e.key !== text[session.current])
      );
    };

    const onKeyDown = (e: React.KeyboardEvent) => {
      if (keyDown) keyDown(e.key.toLowerCase());
      if (isNotAllowedToType(e)) return;

      dispatch({
        type: 'ADD_KEY',
        payload: {
          key: e.key,
          typo: e.key !== text[session.current],
        },
      });
    };

    const onKeyUp = (e: React.KeyboardEvent) => {
      if (keyUp) keyUp(e.key.toLowerCase());
    };

    const onBlur = () => {
      blur();
    };

    return (
      <InputWrapper>
        <Placeholder>
          {session.current === -1 && 'Click to start...'}
        </Placeholder>
        <InputBox>
          <Input
            ref={inputRef}
            tabIndex={0}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onBlur={onBlur}
            show={session.current !== -1}
          />
          {text.map((l, i) => (
            <Letter
              key={i}
              letter={l}
              cursorActive={i === session.current}
              focus={inCurrentWord(i)}
              typo={session.entries[i]?.typo}
              beforeCurrent={i < session.current}
              showProgress
            />
          ))}
        </InputBox>
      </InputWrapper>
    );
  }
);

export default React.memo(RaceInputPanel);
