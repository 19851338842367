import styled from 'styled-components';

const Popup = styled.div`
  padding: 1rem;
  border: 3px solid ${({ theme }) => theme.color.primaryDark};
  border-radius: 1rem;
  font-size: 1.6rem;
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 1rem;
  }
`;

export default Popup;
