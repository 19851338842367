import React, { SetStateAction, useState } from 'react';
import styled from 'styled-components';

const Label = styled.label`
  font-size: 2rem;
`;

const CheckInput = styled.input`
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  &:focus + label:before {
    border-color: ${({ theme }) => theme.color.accent};
  }

  & + label:before {
    content: '';
    margin-right: 10px;
    vertical-align: -10%;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    border: 2px solid ${({ theme }) => theme.color.primary};
    border-radius: 20%;
  }

  &:checked + label:after {
    content: '';
    position: absolute;
    left: 0.5rem;
    top: 1.2rem;
    background: ${({ theme }) => theme.color.primary};
    width: 0.2rem;
    height: 0.2rem;
    box-shadow: 0.2rem 0 0 ${({ theme }) => theme.color.primary},
      0.4rem 0 0 ${({ theme }) => theme.color.primary},
      0.4rem -0.2rem 0 ${({ theme }) => theme.color.primary},
      0.4rem -0.4rem 0 ${({ theme }) => theme.color.primary},
      0.4rem -0.6rem 0 ${({ theme }) => theme.color.primary},
      0.4rem -0.8rem 0 ${({ theme }) => theme.color.primary};
    transform: rotate(45deg);
  }
`;

type UseCheckbox = [React.FC, boolean, SetStateAction<boolean>];

const useCheckbox = (label: string, init = false) => {
  const [selected, setSelected] = useState(init);
  const id = `use-checkbox-${label.replace(' ', '').toLowerCase()}`;

  const Checkbox: React.FC = () => (
    <div>
      <CheckInput
        type="checkbox"
        id={id}
        checked={selected}
        onChange={e => setSelected(e.target.checked)}
      />
      <Label htmlFor={id}>{label}</Label>
    </div>
  );

  return [Checkbox, selected, setSelected] as UseCheckbox;
};

export default useCheckbox;
