import React, { useEffect } from 'react';
import useDropdown, { Option } from 'hooks/useDropdown';
import styled from 'styled-components';
import useCheckbox from 'hooks/useCheckbox';
import useSettings from 'contexts/SettingsContext';
import useUser from 'contexts/UserContext';

const SettingsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

interface PracticeSettingsProps {
  update: () => void;
}

const PracticeSettings: React.FC<PracticeSettingsProps> = ({ update }) => {
  const [settings, setSettings, wordSets, difficulties] = useSettings();
  const [user] = useUser();

  const [WordSetDropdown, wordSetId] = useDropdown(
    'Typing set',
    settings.wordSet ? settings.wordSet.id : 1,
    wordSets,
    !user.data
  );
  const [DifficultyDropdown, difficultyId] = useDropdown(
    'Difficulty',
    settings.difficulty ? settings.difficulty.id : 1,
    difficulties as Option[],
    !user.data
  );
  const [ShowKeyboardCheckbox, showKeyboard] = useCheckbox(
    'Show keyboard',
    settings.showKeyboard
  );
  const [ShowWpmCheckbox, showWpm] = useCheckbox('Show WPM', settings.showWpm);

  useEffect(() => {
    setTimeout(update, 100);
  }, [settings]); // eslint-disable-line

  useEffect(() => {
    setSettings({
      ...settings,
      showWpm,
      showKeyboard,
      wordSet: wordSets.find(w => w.id === wordSetId) ?? wordSets[0],
      difficulty:
        difficulties.find(d => d.id === difficultyId) ?? difficulties[0],
    });
  }, [difficultyId, showKeyboard, showWpm, wordSetId]); // eslint-disable-line

  return (
    <SettingsContainer>
      <WordSetDropdown />
      <DifficultyDropdown />
      <Center>
        <ShowKeyboardCheckbox />
        <ShowWpmCheckbox />
      </Center>
    </SettingsContainer>
  );
};

export default PracticeSettings;
