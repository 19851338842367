import React, { useEffect, useState } from 'react';
import InputPanel from 'components/PracticeInputPanel';
import { Session } from 'types/session';
import { calculateWpm } from 'utils/typing';
import useKeyboard from 'hooks/useKeyboard';
import axios from 'axios';
import {
  CancelIcon,
  PracticeSection,
  SettingsIcon,
  WpmHeader,
} from 'components/PracticePanel/PracticePanel.style';
import IconButton from 'components/IconButton';
import PracticeSettings from 'components/PracticeSettings';
import useSettings from 'contexts/SettingsContext';
import { InputBox } from 'components/Input';

const fetchRandomText = () => {
  return axios
    .get('/api/random', { withCredentials: true })
    .then(res => res.data)
    .catch(null);
};

const PracticePanel: React.FC = () => {
  const [data, setData] = useState('');
  const [wpm, setWpm] = useState(0);
  const [nextText, setNextText] = useState('');
  const [settingsOpen, setSettingsOpen] = useState(false);

  const [settings] = useSettings();
  const [Keyboard, addKey, removeKey, reset] = useKeyboard(
    settings.showKeyboard
  );

  const initFetch = () => {
    fetchRandomText().then(res => {
      setData(res);
    });
  };

  useEffect(initFetch, []);

  const fetchNext = () => {
    fetchRandomText().then(res => setNextText(res));
  };

  const next = (session: Session) => {
    const newWpm = calculateWpm(session.entries, session.history);
    axios.post(
      '/api/session',
      {
        wpm: newWpm,
        keys: session.history,
      },
      { withCredentials: true }
    );
    setWpm(newWpm);
    setData(nextText);
  };

  const headerText = `${wpm > 0 ? `${wpm} ` : '? '}wpm`;

  const wpmHeader = (
    <WpmHeader>
      {settings.showWpm && headerText}
      <IconButton title="Open settings" onClick={() => setSettingsOpen(true)}>
        <SettingsIcon />
      </IconButton>
    </WpmHeader>
  );

  const settingsHeader = (
    <WpmHeader>
      Settings
      <IconButton title="Close settings" onClick={() => setSettingsOpen(false)}>
        <CancelIcon />
      </IconButton>
    </WpmHeader>
  );

  return (
    <PracticeSection>
      {settingsOpen ? settingsHeader : wpmHeader}
      {settingsOpen ? (
        <InputBox>
          <PracticeSettings update={initFetch} />
        </InputBox>
      ) : (
        <InputPanel
          text={data.split('')}
          next={next}
          fetch={fetchNext}
          keyDown={addKey}
          keyUp={removeKey}
          blur={reset}
        />
      )}
      <Keyboard />
    </PracticeSection>
  );
};

export default PracticePanel;
